<template>
    <div>
        <CreatePicklPopup :userId="userId" :OnBoarded="onBoarded" :LoadingBrand="LoadingBrand" :brandId="brandId" popupId="create-pickl-popup" @UpdatePicklList="picklCreated" :surveyFeatureAvailable="surveyFeatureAvailable" :lockPicklFeature="lockPicklFeature"/>
    </div>
</template>

<script>
import CreatePicklPopup from '@/components/common/CreateNonGeoPicklPage.vue'

export default {
  data () {
    return {
      dashboardContent: {},
      busy: true,
      brandId: '',
      onBoarded: 0,
      LoadingBrand: true,
      surveyFeatureAvailable: false,
      lockPicklFeature: false
    }
  },
  name: 'CreatePickl',
  components: {
    CreatePicklPopup
  },
  methods: {
    picklCreated () {
    },
    getBrand () {
      this.$store.dispatch('getBrand', this.$route.params.brandId).then(brand => {
        this.brand = brand
        this.$store.commit('setBrandCredits', this.brand.credits)
        if (this.brand.survey_feature === 1) {
          this.surveyFeatureAvailable = true
        }
        if (this.brand.lock_pickl_feature === 1) {
          this.lockPicklFeature = true
        }
        this.onBoarded = this.brand.on_boarded
        this.LoadingBrand = false
      })
    }
  },
  mounted () {
    this.getBrand()
  },

  created () {
    this.brandId = this.$route.params.brandId
    this.userId = this.$store.state.userData.id
    console.log(this.userId)
  }
}
</script>

<style scoped>
h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    margin-top: 0;
}

.emoticon {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 29px;
        line-height: 45px;
    }
}
</style>
